<template>
  <div class="underline" v-if="size">
    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 no-margin">
      <p>{{ size.displayName }}</p>
    </div>
    <div class="col-xs-6 col-sm-6 col-md-6 no-margin text-right">
      <p v-if="sale" class="ordinary-price">{{ size.ordinaryPrice }}</p>
      <p :class="{ 'sale-price': sale }">{{ size.currentPrice }} :-</p>      
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Object,
      default: null,
    },
  },
  computed: {
    sale() {
      if (this.size) {
        const { currentPrice, ordinaryPrice } = this
        return currentPrice < ordinaryPrice
      }
      return false
    }
  }
}
</script>

<style>

</style>