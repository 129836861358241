<template>
  <div v-if="!showMessageAsSent">
    <p>Det är väldigt viktigt att du uppger rätt kontaktuppgifter så vi kan kontakta dig och bekräfta din beställning.</p>
    <form autocomplete="off" novalidate="" v-on:submit.prevent="send"> 
      <label for="name">Namn *</label>
      <span class="field-validation-error" v-if="isSending && !$v.name.required">Du måste ange ditt namn</span>
      <input name="name" v-model="name" type="text">
      <label for="email">Epost *</label>
      <span class="field-validation-error" v-if="isSending && !$v.email.email">Du måste ange en korrekt epostadress</span>
      <span class="field-validation-error" v-if="isSending && !$v.email.required">Du måste ange din epostadress</span>
      <input name="email" v-model="email" type="email">
      <label for="name">Telefonnummer *</label>
      <span class="field-validation-error" v-if="isSending && $v.phoneNumber.$invalid">Du måste ange ditt telefonnummer</span>
      <input name="text" v-model="phoneNumber" type="text">
      <label for="name">Vad ska det stå på tårtan?</label>
      <input name="text" v-model="textOnCake" type="text">
      <label for="deliveryDate">Välj datum då beställningen ska hämtas *</label>
      <div v-if="isSending && $v.deliveryDate.$invalid">
        <span class="field-validation-error" v-if="!$v.deliveryDate.required">DU MÅSTE ANGE ETT DATUM</span>
        <span class="field-validation-error" v-if="$v.deliveryDate.deliveryDateNoWeekendValidator === false">DU KAN EJ VÄLJA EN SÖNDAG DÅ VI HAR STÄNGT</span>
        <span class="field-validation-error" v-if="!$v.deliveryDate.deliveryDateValidator">DU MÅSTE ANGE ETT DATUM MINST 2 DAGAR FRAMÅT</span>
      </div>
      <input type="date" v-model="deliveryDate" />
      <label for="message">Välj tårta</label>
      <select @change="onProductChange">
          <option v-for="(product, index) in products" :key="index" :value="product.name">{{ product.name }}</option>
      </select><br />
      <label for="message">Välj storlek</label>
      <select @change="onSizeChange" v-if="choosenProduct">
        <option v-for="(size, index) in choosenProduct.sizes" :selected="size.displayName === productSize.displayName" :key="index" :value="size.displayName">{{ size.displayName }}</option>
      </select><br />
      <label for="message">Specifika önskemål eller frågor</label>
      <textarea name="message" v-model="message"></textarea>
      <div class="underline"></div>
      <div class="product" v-if="choosenProduct">
        <h2>{{ choosenProduct.name }}</h2>
        <div class="product-price">
          <div class="underline">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 no-margin"><p>{{ productSize.displayName }}</p></div>
            <div class="col-xs-6 col-sm-6 col-md-6 no-margin text-right">
              <p>{{ (productSize.currentPrice + 100) + ' SEK' }}</p>
            </div>
          </div>
          <div class="product-total">
              <strong class="pull-right">Att betala: {{ (productSize.currentPrice + 100) + ' SEK' }}</strong>
          </div>
        </div>
      </div>
      <button class="call-to-action">Slutför din beställning</button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email, numeric } from 'vuelidate/lib/validators'
import { deliveryDateValidator, deliveryDateNoWeekendValidator } from '@/helpers/validator'

export default {
  computed: {
    ...mapGetters({
      products: 'products/coverProducts',
    }),
  },
  data: () => ({
    name: null,
    email: null,
    message: null,
    phoneNumber: null,
    choosenProduct: null,
    textOnCake: null,
    productSize: null,
    deliveryDate: null,
    isSending: false,
  }),
  methods: {
    async send() {      
      this.isSending = true
      if (!this.$v.$invalid) {
        const { name, email, message, phoneNumber, choosenProduct, productSize, textOnCake, deliveryDate } = this
        this.submit({ name, email, message, phoneNumber, choosenProduct, productSize, textOnCake, deliveryDate })
      }
    },
    chooseProduct(product) {
      if (product) {
        this.choosenProduct = product
        const { sizes } = product
        if (Array.isArray(sizes) && sizes.length > 0) {
          this.productSize = sizes[0]
        }
      }
    },
    onProductChange(e) {
      const { value } = e.currentTarget
      this.chooseProduct(this.products.find(p => p.name === value))
    },
    onSizeChange(e) {
      const { value } = e.currentTarget
      const size = this.choosenProduct.sizes.find(s => s.displayName === value)
      if (size) {
        this.productSize = size
      }
    },
    resetForm() {
      this.isSending = false
      this.name = null
      this.email = null
      this.message = null
      this.textOnCake = null
      this.phoneNumber = null
    },
  },
  mixins: [
    validationMixin,
  ],
  mounted() {
    this.chooseProduct(this.products[0])
  },
  props: {
    submit: {
      type: Function,
      default: () => {},
    },
    showMessageAsSent: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    deliveryDate: {
      required,
      deliveryDateValidator,
      deliveryDateNoWeekendValidator
    },
    phoneNumber: {
      required,
      numeric,
    },
  }
}
</script>

<style></style>
