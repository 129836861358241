import products from '@/data/products.json'

const actions = {
  setCategory({ commit }, category) {
    commit('setCategory', category)
  },
}

const state = {
  products,
  category: null,
}

const getters = {
  all: (state) => state.products,
  filteredProducts: (state) => {
    const { products, category } = state
    if (category && Array.isArray(products)) {
      return products.filter(p => p.category.toLowerCase() === category)
    }
    return []
  },
  coverProducts: (state) => state.products.filter(p => p.coverCake),
}

const mutations = {
  resetCategory(state) {
    state.category = null
  },
  setCategory(state, category) {
    state.category = category
  }
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
}
