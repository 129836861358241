<template>
  <div class="app">
    <MobileNav />
    <div id="panel">
        <div class="holder">
          <Header />
          <router-view />
          <Footer />
        </div>
    </div>
  </div>
</template>

<script>
import { breakpoint } from 'vuex-breakpoint'
import { Header, Footer, MobileNav } from '@/components'

export default {
  components: {
    Header,
    Footer,
    MobileNav,
  },
  mixins: [
    breakpoint,
  ]
}
</script>

<style lang="scss">
@import "~vuex-breakpoint/src/scss/breakpoints";
@import "~@/assets/css/style.css";

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  #panel {
    & > .holder {
      display: flex;
      width: 100%;
      min-height: 100vh;
      flex-direction: column;
    }
  }
}

$breakpoints: (
  ("thumb", 0px, 576px),
  ("handheld", 577px, 768px),
  ("lap", 769px, 992px),
  ("desktop", 993px, null)
);

@include breakpoints($breakpoints);
</style>
