<template>
  <section class="dark red">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 owl-theme">
          <div ref="carousel">
            <carousel-item :headline="item.title" :imageUrl="item.media.secure_url" :linkText="item.linkText" :linkUrl="item.link" :text="item.content" v-for="(item, index) in newsItems" :key="index" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { tns } from 'tiny-slider/src/tiny-slider'
import CarouselItem from './CarouselItem.vue'

export default {
  components: {
    CarouselItem,
  },
  computed: {
    ...mapGetters({
      newsItems: 'news/newsInCarousel',
    }),
  },
  mounted() {
    this.loadSlider()
  },
  methods: {
    loadSlider() {
      const { carousel } = this.$refs
      if (carousel) {
        this.$nextTick(() => {
          this.slider = tns({
            container: carousel,
            items: 4,
            mouseDrag: true,
            nav: false,
            controls: false,
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 2,
              },
              1000: {
                items: 4,
              },
            },
          })
        })
      }
    },
  },
}
</script>
