<template>
  <div>
    <p class="message error" v-if="isSending && !isValid">Något blev fel. Var vänlig och kontrollera dina uppgifter och försök igen.</p>
    <p class="message success" v-if="messageSent">Ditt meddelande har nu skickats.</p>
    <h3 class="underline">{{ title }}</h3>
    <form autocomplete="off" novalidate="" v-on:submit.prevent="send"> 
      <label for="name">Namn *</label>
      <FieldValidationMessage :show="isSending && !nameIsValid" message="Du måste ange ditt namn" />
      <input name="name" v-model="name" ref="name" required="" type="text">
      <label for="email">Epost *</label>
      <FieldValidationMessage :show="isSending && !emailIsValid" message="Du måste ange din epost" />
      <input name="email" v-model="email" ref="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required="" type="email">
      <label for="message">Meddelande *</label>
      <FieldValidationMessage :show="isSending && !messageIsValid" message="Du måste skriva något" />
      <textarea name="message" v-model="message" ref="message" required></textarea>
      <input type="hidden" v-model="check" />
      <button>Skicka</button>
    </form>
  </div>
</template>

<script>
import FieldValidationMessage from './FieldValidationMessage.vue'
import api from '@/helpers/api'

export default {
  components: {
    FieldValidationMessage,
  },
  computed: {
    emailValid() {
      const { email } = this.$refs
      if (email) {
        return email.validity.valid
      }
      return false
    },
    nameValid() {
      const { name } = this.$refs
      if (name) {
        return name.validity.valid
      }
      return false
    },
    messageValid() {
      const { message } = this.$refs
      if (message) {
        return message.validity.valid
      }
      return false
    },
    isValid() {
      return this.emailValid && this.nameValid && this.messageValid
    },
  },
  data: () => ({
    name: null,
    email: null,
    message: null,
    check: null,
    nameIsValid: false,
    emailIsValid: false,
    messageIsValid: false,
    isSending: false,
    messageSent: false,
  }),
  methods: {
    async send() {
      this.isSending = true
      if (this.validate()) {
        const { name, email, message } = this
        const { data: { isValid } } = await api.contact({ name, email, message })
        if (isValid) {
          this.messageSent = true
          this.resetForm()
          setTimeout(() => {
            this.messageSent = false
          }, 3000)
        }
      }
    },
    resetForm() {
      this.isSending = false
      this.name = null
      this.email = null
      this.message = null
      this.nameIsValid = false
      this.messageIsValid = false
      this.emailIsValid = false
    },
    validate() {

      if (this.check) {
        return false
      }

      const { name, email, message } = this.$refs
      if (name) {
        this.nameIsValid = name.validity.valid
      }
      if (email) {
        this.emailIsValid = email.validity.valid
      }
      if (message) {
        this.messageIsValid = message.validity.valid
      }
      return this.nameIsValid && this.emailIsValid && this.messageIsValid
    },
  },
  props: {
    title: {
      type: String,
      default: 'Kontakta oss',
    },
  },
}
</script>

<style></style>
