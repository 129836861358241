const deliveryDateNoWeekendValidator = (value) => {
  var deliveryDate = new Date(value)
  var requiredDate = new Date()
  requiredDate.setDate(requiredDate.getDate() + 1)
  if (deliveryDate.getDay() == 0) {
    return false
  }
  return true
}

const deliveryDateValidator = (value) => {
  var deliveryDate = new Date(value)
  var requiredDate = new Date()
  requiredDate.setDate(requiredDate.getDate() + 1)
  if (deliveryDate < requiredDate) {
    return false
  }
  return true
}

export {
  deliveryDateNoWeekendValidator,
  deliveryDateValidator,
}
