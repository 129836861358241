import axios from 'axios'

const { VUE_APP_SERVER_URL } = process.env
const baseAxios = axios.create({
  baseURL: `${VUE_APP_SERVER_URL}/`,
})

const endpoints = () => ({
  getNews: () => baseAxios.post('news'),
  cakeCoverOrder: (order) => baseAxios.post('order/cakecover', order),
  contact: ({ name, email, message }) => baseAxios.post('contact', { name, email, message })
})

export default endpoints()