<template>
  <footer class="dark">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-4 margin-top">
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <p class="info small">© 2020 DAGMARCARLSSONS.SE ALL RIGHTS RESERVED</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
}
</script>

<style lang="scss">
footer {
  margin-top: auto;
}
</style>
