<template>
  <div class="owl-item">
      <img class="scale frame light" :src="imageUrl">
      <h2>{{ headline }}</h2>
      <p>{{ text }}</p>
      <a class="read-more light" :href="linkUrl">{{ linkText }}</a>
    </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    imageUrl: {
      type: String,
      default: '',
    },
    headline: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    linkUrl: {
      type: String,
      default: '',
    },
    linkText: {
      type: String,
      default: '',
    },
  },
}
</script>

<style>

</style>