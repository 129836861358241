<template>
  <section class="content-area light">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <h2 class="underline">Ordinarie tårtor</h2>
          <div class="row products">
            <div v-for="(product, index) in products" :key="index" class="col-md-6 col-sm-6 product" >
              <Product :product="product" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Product from './Product.vue'

export default {
  components: {
    Product,
  },
  props: {
    products: {
      type: Array,
      default: null,
    },
  },
  mounted() {
    this.$nextTick(() => {

    })
  }
}
</script>

<style>
.products {
  display: flex;
  flex-wrap: wrap;
}
</style>