const actions = {
  open({ commit }, open) {
    commit('open', open)
  },
}

const state = {
  isOpened: false,
}

const getters = {
  isOpened: (state) => state.isOpened,
}

const mutations = {
  open(state, open) {
    state.isOpened = open
  },
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
}
