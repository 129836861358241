<template>
  <Slideout :touch="false" :toggleSelectors="['.toggle-button']" menu="#menu" panel="#panel" side="right">
    <nav id="menu" class="mobile-menu">
      <section class="menu-section">
        <ul class="menu-section-list">
          <li>
            <router-link to="/">
              <span @click="close">Startsida</span>
            </router-link>
          </li>
          <li>
            <router-link to="/om-oss">
              <span @click="close">Om oss</span>
            </router-link>
          </li>
        </ul>
        <h3 class="menu-section-title">Bakverk</h3>
        <ul class="menu-section-list with-title">
          <li>
            <router-link to="/sortiment/ordinarie">
              <span @click="close">Ordinarie tårtor</span>
            </router-link>
          </li>
          <li>
            <router-link to="/sortiment/bestall-tarta-med-eget-motiv">
              <span @click="close">Beställ tårta</span>
            </router-link>
          </li>
          <li>
            <router-link to="/sortiment/special">
              <span @click="close">Specialtårtor</span>
            </router-link>
          </li>
        </ul>
        <ul class="menu-section-list">
          <li>
            <router-link to="/kontakta-oss">
              <span @click="close">Kontakta oss</span>
            </router-link>
          </li>
        </ul>
      </section>
    </nav>
  </Slideout>
</template>

<script>
import { mapGetters } from 'vuex' 
import Slideout from 'vue-slideout'

export default {
  components: {
    Slideout,
  },
  computed: {
    ...mapGetters( {
      currentBreakpoint: 'breakpointStore/currentBreakpoint',
      mobileNavIsOpened: 'mobilenav/isOpened',
    }),
  },
  methods: {
    close() {
      this.$children[0].slideout.toggle()
      this.$store.dispatch('mobilenav/open', false)
    },
  },
  watch: {
    currentBreakpoint(currentBreakpoint) {
      if (['lap', 'desktop'].includes(currentBreakpoint) && this.mobileNavIsOpened) {
        this.close()
      }
    },
  },
}
</script>

<style>

</style>