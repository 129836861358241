<template>
  <div>
    <h2 class="underline">Öppettider</h2>
    <!-- <div class="row">
      <div class="col-xs-12"><p>Under juni, juli och augusti har vi följande öppettider</p></div>
    </div> -->
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><p>Måndag - Fredag</p></div>
      <div class="col-xs-6  col-sm-6 col-md-6 col-lg-6"><p>06:00 - 17:00</p></div>
    </div>
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><p>Lördag</p></div>
      <div class="col-xs-6  col-sm-6 col-md-6 col-lg-6"><p>08:00 - 14:00</p></div>
    </div>
     <!-- <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><p class="red">Semesterstängt</p></div>
      <div class="col-xs-6  col-sm-6 col-md-6 col-lg-6"><p class="red">8/7 - 12/8</p></div>
    </div> -->
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
strong {
  margin-bottom: 10px;
  display: block;
}
</style>
