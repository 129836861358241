<template>
  <div v-if="show">
    <span class="field-validation-error">{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.field-validation-error {
  text-transform: uppercase;
}
</style>