import Vue from 'vue'
import VueRouter from 'vue-router'

const routes = [
  {
    name: 'start',
    path: '/',
    component: () => import('@/pages/Start.vue'),
  },
  {
    name: 'about',
    path: '/om-oss',
    component: () => import('@/pages/About.vue'),
  },
  {
    name: 'contact',
    path: '/kontakta-oss',
    component: () => import('@/pages/Contact.vue'),
  },
  {
    name: 'cakecover',
    path: '/sortiment/bestall-tarta-med-eget-motiv',
    component: () => import ('@/pages/CakeCover.vue'),
  },
  {
    name: 'products',
    path: '/sortiment/:category',
    component: () => import ('@/pages/Products.vue'),
  },
  {
    name: 'notfound',
    path: '*',
    component: () => import('@/pages/NotFound.vue'),
  },
]

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes,
})

export default router