<template>
  <header class="site-header">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="holder">
            <router-link to="/">
              <img class="logo logo-retina" height="auto" src="@/assets/img/logo1@2x.png" width="273">
              <img class="logo" height="auto" src="@/assets/img/logo1.png" width="273">  
            </router-link>
            <button @click="toggleMobileNav" :class="{ 'is-active': mobileNavIsOpened }" class="toggle-button visible-sm-inline visible-xs-inline c-hamburger c-hamburger--htx">
              <span>toggle menu</span>
            </button>
          </div>           
        </div>
        <div class="col-md-6 visible-md visible-lg">
          <div class="vertical-align pull-right visible-md-block visible-lg-block">
            <nav aria-label="Main menu" role="navigation">
              <ul aria-hidden="false" id="nav" role="menubar">
                <li aria-haspopup="false" role="menuitem">
                  <router-link to="/">
                    Startsida
                  </router-link>
                </li>
                <li aria-haspopup="false" role="menuitem">
                  <router-link to="/om-oss">
                    Om oss
                  </router-link>
                </li>
                <li aria-haspopup="true" tabindex="0">
                  <router-link to="/sortiment/ordinarie" v-slot="{ href, navigate }" class="dd-arrow">
                    <a :href="href" @click="navigate" :class="{ 'active': ['products', 'cakecover'].includes(currentRouteName) }">Bakverk</a>
                  </router-link>
                  <ul aria-hidden="true" data-test="true" role="menu">
                    <li role="menuitem">
                      <router-link to="/sortiment/ordinarie">
                        Ordinarie tårtor
                      </router-link>
                    </li>
                    <li role="menuitem">
                      <router-link to="/sortiment/bestall-tarta-med-eget-motiv">
                        Beställ tårta med eget motiv
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/sortiment/special">
                        Specialtårtor
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li aria-haspopup="false" role="menuitem">
                  <router-link to="/kontakta-oss">
                    Kontakta oss
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex' 
export default {
  computed: {
    ...mapGetters({
      mobileNavIsOpened: 'mobilenav/isOpened',
    }),
    currentRouteName() {
      return this.$route.name
    },
  },
  methods: {
    toggleMobileNav() {
      this.$store.dispatch('mobilenav/open', !this.mobileNavIsOpened)
    },
  },
}
</script>
<style lang="scss">
.site-header {
  .holder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    a {
      margin: 0 20px 0 0;
    }

    img {
      margin: 0;
    }

    button {
      margin-left: auto;
      flex: 0 0 38px;
    }
  }
}

</style>