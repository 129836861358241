<template>
  <div v-if="product">
    <h2>{{ product.name }}</h2>
    <img class="scale frame light" height="450" width="450" :src="product.picture.secure_url">
    <div class="product-information">
      <p class="product-description">{{ product.description }}</p>
    </div>
    <div class="product-price">
      <ProductSizePrice v-for="(size, index) in product.sizes" :key="index" :size="size" />
    </div>
  </div>
</template>

<script>
import ProductSizePrice from './ProductSizePrice.vue'

export default {
  components: {
    ProductSizePrice,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style>

</style>