import newsList from '@/data/news.json'

const actions = {}

const state = {
  news: newsList,
}

const getters = {
  allNews: (state) => state.news,
  newsInCarousel: (state) => state.news.filter((n) => n.useInSlider === true)
}

const mutations = {}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
}
