import Vue from 'vue'
import Vuex from 'vuex'
import { breakpointStore } from 'vuex-breakpoint'
import news from './news'
import products from './products'
import mobilenav from './mobilenav'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    news,
    products,
    breakpointStore,
    mobilenav,
  },
})