<template>
  <div class="section dark top dark-red">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>{{ title }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style>

</style>